@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .gradient-0 {
    @apply bg-gradient-to-br from-[#5B8DEF] to-[#0063F7];
  }

  .gradient-1 {
    @apply bg-gradient-to-br from-[#FF8800] to-[#E63535];
  }

  .gradient-2 {
    @apply bg-gradient-to-br from-[#3E7BFA] to-[#6600CC];
  }

  .gradient-3 {
    @apply bg-gradient-to-br from-[#00CFDE] to-[#05A660];
  }

  .gradient-4 {
    @apply bg-gradient-to-b from-[#0F0C29] via-[#302B63] to-[#24243E];
  }

  .gradient-5 {
    @apply bg-gradient-to-br from-[#FDDD48] to-[#00B7C4];
  }

  .gradient-6 {
    @apply bg-gradient-to-br from-[#FF3B3B] to-[#6600CC];
  }

  .gradient-7 {
    @apply bg-gradient-to-br from-[#73DFE7] to-[#0063F7];
  }

  .gradient-8 {
    @apply bg-gradient-to-br from-[#AC5DD9] to-[#004FC4];
  }

  .gradient-9 {
    @apply bg-gradient-to-br from-[#5B8DEF] to-[#0063F7];
  }

  .gradient-main {
    @apply bg-gradient-to-b from-[#F0F5FF] to-[#fff];
  }

  .gradient-second {
    @apply bg-gradient-to-b from-[#D6E4FF] to-[#fff];
  }

  .gradient-third {
    @apply bg-gradient-to-b from-[#F4D6FF] to-[#fff];
  }

  .text-gradient-7 {
    @apply bg-clip-text text-transparent gradient-7;
  }

  .hover-anime {
    @apply transition ease-in-out duration-700;
  }
}

.sidebar::-webkit-scrollbar {
  width: 0 !important;
}
.sidebar {
  overflow: -moz-scrollbars-none;
}
.sidebar {
  -ms-overflow-style: none;
}

#map_sual {
  border: 0 !important;
}

#map_sual .gm-style > div {
  border: 0 !important;
}

#map_sual .gm-style-iw-t {
  bottom: 50px !important;
}

#map_sual .gm-style .gm-style-iw-c {
  padding: 0;
  border: 0 !important;
  outline: none;
}

#map_sual .gm-style-iw-d {
  overflow: auto !important;
}

#map_sual .gm-ui-hover-effect {
  display: none !important;
}

#map_sual .gm-style .gm-style-iw-tc::after {
  display: none;
}

.pulsating-circle {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}

.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #3ddb6c;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #2cbc65;
  border-radius: 15px;

  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
